import React, { CSSProperties, HtmlHTMLAttributes, forwardRef } from 'react'
import {
  Text,
  Button
} from '@nextui-org/react'
import { templateId } from 'utils'
import { ButtonProps, Text as MText, createPolymorphicComponent, TextProps } from '@mantine/core'
import { styled } from 'styled-components'

type TextTransforms =
  /* Keyword values */
  | "none"
  | "capitalize"
  | "uppercase"
  | "lowercase"
  | "full-width"
  | "full-size-kana"
  /* Global values */
  | "inherit"
  | "initial"
  | "revert"
  | "unset";

type TextWeights =
  | 'hairline'  // 100,
  | 'thin'  // 200,
  | 'light'  // 300,
  | 'normal'  // 400,
  | 'medium'  // 500,
  | 'semibold'  // 600,
  | 'bold'  // 700,
  | 'extrabold'  // 800,
  | 'black';  // 900


export const GeneralText: React.FC<{
  size: string | number
  color: string
  mt?: string
  transform?: TextTransforms
  weight: TextWeights
  cusColor?: string
  wordB?: string
  textAlign?: string
  children: React.ReactNode
}> = ({ size, color, mt, transform, weight, cusColor, wordB, textAlign, children }) => (
  <Text size={size} color={color} transform={transform} weight={weight} css={{
    marginTop: mt,
    color: cusColor,
    wordBreak: wordB,
    fontFamily: 'var(--fontFamily)',
    textAlign
  }}>
    {children}
  </Text>
)

export const ButtonStyled: React.FC<{
  width?: string
  height?: string
  borderRadius?: string
  isLoading?: boolean
  onClick?: any
  disabled?: boolean
  fSize?: string
  children: React.ReactNode
}> = ({ width, height, borderRadius, isLoading, onClick, disabled, fSize, children }) => (
  <Button
    rounded={templateId === '1' || templateId === '3'}
    css={{
      background: templateId === '1' ? '#DC70FA' : '#3772FF',
      width,
      height,
      borderRadius,
      fontSize: fSize
    }}
    iconRight={isLoading ? <i className="pi pi-spin pi-sync" style={{ fontSize: '1rem' }} /> : null}
    onPress={onClick}
    disabled={disabled}
  >
    {children}
  </Button>
)


// Text
const _StyledText = forwardRef<HTMLButtonElement, { children: React.ReactNode, other }>(({ children, ...other }) => (
  <MText size='16px' color='var(--whiteColor)' fw={400} ff='var(--fontFamily)' lh={1.5} {...other}>
    {children}
  </MText>
))
export const StyledText = createPolymorphicComponent<'button', TextProps>(_StyledText)


// Button
export const StyledButton: React.FC<{
  width?: string;  minWidth?: string; maxWidth?: string;
  height?: string; minHeight?: string; maxHeight?: string;
  borderRadius?: string; isLoading?: boolean; onClick?: any;
  disabled?: boolean; fSize?: string; bgColorCode?: string;
  border?: string; margin?: string; padding?: string; style?: CSSProperties;
  color?: string; zIndex?: number; children: React.ReactNode
}> = ({
  width, minWidth, maxWidth, height, minHeight, maxHeight, borderRadius, isLoading, onClick,
  disabled, fSize, bgColorCode, border, margin, padding, color, zIndex, style, children
}) => (
  <Button
    css={{
      background: bgColorCode || 'var(--btnMainColor)',
      color: color || 'var(--whiteColor)',
      borderRadius: borderRadius || '10px',
      border,
      width: width || 'auto',
      height: height || 'auto',
      minWidth: minWidth || '48px',
      minHeight,
      maxWidth,
      maxHeight,
      fontSize: fSize,
      margin,
      padding,
      zIndex
    }}
    iconRight={ isLoading ? <i className="pi pi-spin pi-sync" style={{ fontSize: '1rem' }} /> : null }
    onPress={() => onClick()}
    disabled={disabled}
    style={style}
  >
    {children}
  </Button>
)


// Page component as main wrapper
const StyledPage = styled.div`
  min-height: calc(100vh - 76px);
  box-sizing: border-box;
`
export const Page: React.FC<HtmlHTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <>
      <StyledPage {...props}>
        {children}
      </StyledPage>
    </>
  )
}


// hotizontal divider for modal, card etc
export const Divider = styled.div<{ bgColor?: string; margin?: string; height?: string }>`
  background-color: ${props => props.bgColor === undefined ? "var(--textGrey)" : props.bgColor};
  height: ${props => props.height === undefined ? "1px" : props.height};
  width: 100%;
  min-height: 1px;
  margin: ${props => props.margin === undefined ? "1rem auto" : props.margin};
`

// list container
export const ListContainer = styled.div<{ maxscreennumberperrow?: number; bigscreengap?: string; smallscreengap?: string; }>`
  --template-column-gutters: ${props => props.smallscreengap === undefined ? "8px" : props.smallscreengap};
  --template-columns: 1;
  --template-column-compact-multiplier: 1;
  --template-reduced-columns: 0;
  --template-reduced-columns-multiplier: 1;

  display: grid;
  gap: var(--template-column-gutters);
  grid-auto-rows: minmax(0px, 1fr);
  grid-template-columns: repeat( calc( var(--template-columns) - ( var(--template-reduced-columns) * var(--template-reduced-columns-multiplier) ) ), minmax(0, 1fr) );

  @media only screen and (min-width: 320px) {
    --template-columns: 1;
  }
  @media only screen and (min-width: 480px) {
    --template-columns: 1;
  }
  @media only screen and (min-width: 768px) {
    --template-column-gutters: ${props => props.bigscreengap === undefined ? "16px" : props.bigscreengap};
  }
  @media only screen and (min-width: 800px) {
    --template-columns: 2;
  }
  @media only screen and (min-width: 1024px) {
    --template-columns: 3;
    --template-reduced-columns: 1;
  }
  @media only screen and (min-width: 1120px) {
    --template-columns: 4;
  }
  @media only screen and (min-width: 1440px) {
    --template-columns: ${props => props.maxscreennumberperrow === undefined ? "5" : props.maxscreennumberperrow + 1};
  }
`