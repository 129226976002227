
export const EN = { locale: 'en-US', language: 'English', code: 'en' }
export const JA = { locale: 'ja-JP', language: '日本語', code: 'ja' }
export const KO = { locale: 'ko-KR', language: '한국어', code: 'ko' }
export const ZHCN = { locale: 'zh-CN', language: '简体中文', code: 'zh-cn' }
// export const ZHTW = { locale: 'zh-TW', language: '繁體中文', code: 'zh-tw' }
export const MSMY = { locale: 'ms-MY', language: 'Malay (Malaysia)', code: 'ms-my' }

export const languages = {
  'en-US': EN,
  // 'ja-JP': JA,
  // 'ko-KR': KO,
  'zh-CN': ZHCN,
  // 'zh-TW': ZHTW,
  'ms-MY': MSMY,
}

export const languageList = Object.values(languages)