import { CHAIN_ID } from 'config/constants/networks'
import addresses from 'config/constants/contracts'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address, chainId: number): string => {
  if (!address) return ""
  return address[chainId] ? address[chainId] : address[CHAIN_ID.MAINNET]
}

export const getMulticallAddress = (chainId: number) => {
  return getAddress(addresses.multiCall, chainId)
}

export const getPurchaseCashierAddress = (chainId: number) => {
  return getAddress(addresses.purchaseCashierContr, chainId)
}

export const getNftAddress = (chainId: number) => {
  return getAddress(addresses.nftContr, chainId)
}

export const getClaimHandlerAddress = (chainId: number) => {
  return getAddress(addresses.claimHandlerContr, chainId)
}