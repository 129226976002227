import { StyledText } from 'components/GlobalStyle'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const Footer: React.FC = () => {
  const currentYear = new Date().getFullYear()

  return (
    <div style={{ background: 'linear-gradient(0deg, #1B1B1C 0%, #2F302F 100%)', padding: '80px 20px'}}>
      <StyledText size='clamp(30px, 8vw, 65px)' ff='var(--fontFamilyB)' ta='center'>
        <FormattedMessage id='footer-slogan' defaultMessage='Hunt Fun Earn' />
      </StyledText>
      <StyledText color='#808184' ta='center' mt={40}>
        <FormattedMessage id='label-copyright' defaultMessage='CopyRight' />
        &nbsp;&copy; {currentYear} {process.env.REACT_APP_PROJECT_NAME}&nbsp;
        <FormattedMessage id='label-rights' defaultMessage='All Rights Reserved' />
      </StyledText>
    </div>
  )
}

export default Footer