import React, { useState, useMemo } from 'react'
import { Dropdown } from '@nextui-org/react'
import { useTranslation } from 'contexts/I18nContext'
import { templateId } from 'utils'
import { languageList } from 'config/constants/languages'

const LangSelector: React.FC = () => {
  const { setLanguage, currentLanguage } = useTranslation()
  const [selected, setSelected] = useState(new Set([currentLanguage.locale]))
  const selectedValue = useMemo(() =>
    Array.from(selected),
    [selected]
  )

  return (
    <Dropdown>
      <Dropdown.Button className='lang-dd-btn'
        rounded
        css={{
          background: 'transparent'
        }}
      >
        {languageList.find(l => l.locale === selectedValue.toString())?.code.toLocaleUpperCase()}
      </Dropdown.Button>
      <Dropdown.Menu
        color="default"
        disallowEmptySelection
        selectionMode="single"
        selectedKeys={selected}
        onSelectionChange={(la) => {
          setSelected(la as Set<string>)
          const newLocale = Object.values(la)[0]
          setLanguage(languageList.find(l => l.locale === newLocale.toString()))
        }}
      >
        {languageList.map((la) => (
          <Dropdown.Item key={la.locale}>{la.language}</Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default LangSelector